import {mapActions} from "vuex";

const GeneralMixin = {
    data() {
        return {}
    },
    computed: {
        groupName() {
            let origin = window.location.origin;
            if(origin.includes('malpiins')) {
                return 'Clubs';
            }
            return 'Group'
        },
    },
    methods: {
        ...mapActions([
            "GET_USER_PERMISSIONS",
        ]),

        checkIsAccessible(menu, subMenu = '') {
            let user = this.getCurrentUser();
            let permissions = this.$store.getters.getPermissions;
            if (user.account_type == 'admin' && permissions[menu] && permissions[menu].length > 0) {
                return (permissions[menu].indexOf(subMenu) != -1) ? true : false;
            } else
                return false;

        },

        getCurrentUser() {
            return this.$store.getters.currentUser;
        },

        displayMessage(type, msg = null) {
            let defaultSuccessMessage = "Information updated";
            let defaultErrorMessage = "Opps something went wrong please try again later";
            if (type == "success") {
                this.$snotify.success(msg ? msg : defaultSuccessMessage)
            } else {
                this.$snotify.error(msg ? msg : defaultErrorMessage)
            }
        },
        getBadge(status_text) {
            status_text = status_text.toLowerCase()
            switch (status_text) {
                case "completed":
                    return `<span class="badge badge-primary">${status_text.toUpperCase()}</span>`
                case "result added":
                    return `<span class="badge badge-success">${status_text.toUpperCase()}</span>`
                case "cancelled":
                    return `<span class="badge badge-danger">${status_text.toUpperCase()}</span>`
                case "postponed":
                    return `<span class="badge badge-danger">${status_text.toUpperCase()}</span>`
                default:
                    return `<span class="badge badge-secondary">${status_text.toUpperCase()}</span>`
            }
        }
    }
};

export default {
    install(Vue, options) {
        Vue.mixin(GeneralMixin)
    }
}


