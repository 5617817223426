const IDCard = {

    state: {
        principal_sign: null,
        barcode: null
    },
    getters: {
        getBarcode(state) {
            return state.barcode;
        },
        getPrincipalSign(state) {
            return state.principal_sign;
        },
    },
    actions: {
        GET_PRINCIPAL_SIGN: (context, payload) => {
            context.commit('SET_PRINCIPAL_SIGN', payload)
        },
        GET_BARCODE: (context, payload) => {
            context.commit('SET_BARCODE', payload)
        }
    },
    mutations: {
        SET_PRINCIPAL_SIGN: (state, payload) => {
            state.principal_sign = payload
        },
        SET_BARCODE: (state, payload) => {
            state.barcode = payload
        }
    },
}
export default IDCard;
