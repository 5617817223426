
//Get Domain name it is running on
var domain = (function(){
    var i=0,domain=document.domain,p=domain.split('.'),s='_gd'+(new Date()).getTime();
    while(i<(p.length-1) && document.cookie.indexOf(s+'='+s)==-1){
        domain = p.slice(-1-(++i)).join('.');
    }
    return domain;
})();
var maindomain = domain.replace(/^[^.]+\./g, "");

//Check if domain is allowed & redirect if not allowed domain to buy
// var allowed_domain = [
//      //Stage Website
//     "kist.stage",
//     //Site Allowed to access the app
//     "kist.edu.np",
//     "yelkoedu.com",
//     "qmc.edu.np",
// ];
// if(allowed_domain.indexOf(maindomain) !== -1){
    // Domain is allowed & valid. Set protocol to run app
    maindomain = process.env.VUE_APP_PROTOCOL+'api.'+maindomain+'/';

// } else{
//     Domain is not allowed. Redirect it to buy page
    // window.location='https://yelkoedu.com/buy-now';
// }


export const API_URL = maindomain;
export default API_URL;

