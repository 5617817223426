import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import UserPermission from './userpermission';
import RouterPermission from './routerpermission';
import idcard from './id_card';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        htmlClass,
        config,
        idcard,
        breadcrumbs,
        UserPermission,
        RouterPermission,
    }
});
