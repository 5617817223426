import Vue from "vue";
import Router from "vue-router";
// RouterTabRoutes
Vue.use(Router);

export default new Router({
    routes: [
        {
            path: "",
            redirect: "/dashboard",
            component: () => import("@/view/layout/Layout"),
            children: [
                {
                    path: "chat",
                    name: "chat",
                    meta: {

                        title: 'Chat',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/chat/Chat.vue")
                },
                {
                    path: "predefined-response-category",
                    name: "predefined-response-category",
                    meta: {

                        title: 'Predefined response category',
                        icon:'fa fa-envelope',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/predefined-response/category/Index.vue")
                },
                {
                    path: "predefined-response",
                    name: "predefined-response",
                    meta: {

                        title: 'Predefined response',
                        icon:'fa fa-envelope',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/predefined-response/Index.vue")
                },
                {
                    path: "library/dashboard",
                    name: "library-dashboard",
                    meta: {
                        permission: "library-book.list",
                        title: 'Library dashboard',
                        icon: 'fas fa-home',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/library-book/LibraryDashboard.vue")
                },
                {
                    path: "library/all-books",
                    name: "all-books",
                    meta: {
                        permission: "library-book.list",
                        title: 'All books',
                        icon:'fas fa-book',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/library-book/book/Index.vue")
                },
                {
                    path: "library/lost-book",
                    name: "lost-book-list",
                    meta: {
                        permission:"library-book.list",
                        title: 'All lost books', // tab title
                        icon:'fas fa-book',
                        tabClass: 'custom-tab', // custom class, optional
                        closable: true, // is tab closable, defaults to `true`
                        key: 'fullPath',
                    },
                    component: () => import("@/view/pages/library-book/book/LostBook.vue")
                },
                {
                    path: "library/issued",
                    name: "issued-list",
                    meta: {
                        permission:"library-issue.list",
                        title: 'Books issued',
                        icon: 'fas fa-arrow-circle-left',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/library-book/issue/Index.vue")
                },
                {
                    path: "library/returned",
                    name: "returned-list",
                    meta: {
                        permission:"library-issue.list",
                        title: 'Books returned', // tab title
                        tabClass: 'custom-tab', // custom class, optional
                        closable: true, // is tab closable, defaults to `true`,
                        icon:'fas fa-arrow-circle-right',
                        key: 'fullPath',
                    },
                    component: () => import("@/view/pages/library-book/return/Index.vue")
                },
                {
                    path: "library/not-returned-book",
                    name: "not-returned-book",
                    meta: {
                        permission:"library-issue.list",
                        title: 'Not returned book',
                        icon: 'fas fa-arrow-circle-down',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/library-book/issue/NotReturnedBook.vue")
                },
                {
                    path: "dashboard",
                    name: "dashboard",
                    meta: {
                        permission: "dashboard",
                        title: 'Dashboard', // tab title
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: false // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/Dashboard.vue")
                },
                {
                    path: "apply-for-enrolment",
                    name: "apply-enrolment",
                    meta: {

                        title: 'Enrollment', // tab title
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/enrolment/EnrolmentForm.vue")
                },
                {
                    path: "apply-for-enrolment/:id",
                    name: "apply-enrolment-update",
                    meta: {

                        title: 'Enrollment', // tab title
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/enrolment/EnrolmentForm.vue")
                },
                {
                    path: "exams-grade-point",
                    name: "exams-grade-point",
                    meta: {

                        title: 'Exam grade point', // tab title
                        icon:'far fa-file-excel',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/grade-point/Index.vue")

                },
                {
                    path: "grade-point/:id/values/different-course",
                    name: "grade-point-different-course",
                    meta: {

                        title: 'Exam grade point', // tab title
                        icon:'far fa-file-excel',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/grade-point/CourseGradePoint.vue")

                },
                {
                    path: "exams",
                    name: "exams",
                    meta: {

                        title: 'Exams', // tab title
                        icon: 'fa-regular fa-newspaper',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/exams/Index.vue")

                },
                {
                    path: "exam/:slug/attendance/course/:courseId/students",
                    name: "exam-attendance-courses-students",
                    meta: {

                        title: 'Exam attendance', // tab title
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/exams/summary/ExamAttendance.vue")

                },
                {
                    path: "address-book",
                    name: "address-book",
                    meta: {

                        title: 'Contacts', // tab title
                        icon: 'fa-solid fa-address-card',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/address/book/Index.vue")

                },
                {
                    path: "address-book/create",
                    name: "book-create",
                    meta: {

                        title: 'New address-book',
                        icon: 'fas fa-plus-circle',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/address/book/CreateAndUpdate.vue")

                }, {
                    path: "address-book/:id/update",
                    name: "book-update",
                    meta: {

                        title: 'Edit address-book',
                        icon: 'fas fa-edit',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/address/book/CreateAndUpdate.vue")

                },

                {
                    path: "exams-summary/:exam_id",
                    name: "exams-summary",
                    meta: {

                        title: 'Exam summary', // tab title
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/exams/ExamSummary.vue")

                },
                {
                    path: "teachers/results",
                    name: "teacher-results",
                    meta: {

                        title: 'Teacher results', // tab title
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/results/Index.vue"),
                },
                {
                    path: "teachers/courses",
                    name: "teacher-courses",
                    meta: {

                        title: 'Teacher courses', // tab title
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/teacher/courses/teacher-courses.vue"),
                },
                {
                    path: "results",
                    name: "results",
                    meta: {

                        title: 'Results', // tab title
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/results/Index.vue"),
                },

                {
                    path: "results/:slug/courses",
                    name: "results-view-all-courses",
                    meta: {

                        title: 'Results courses', // tab title
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/results/courses/Index.vue")
                },
                {
                    path: "results/exam/:slug/course/:courseId/students",
                    name: "results-view-all-courses-students",
                    meta: {

                        title: 'Result entry', // tab title
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/results/courses/Students.vue")
                },
                {
                    path: "results/exam/:slug/course/:courseId/assessments",
                    name: "results-view-courses-assessments",
                    meta: {

                        title: 'Assessments for exams', // tab title
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/results/courses/CourseAssessment.vue")
                },
                {
                    path: "results/exam/:slug/course/:courseId/assessment/:assessmentId/students",
                    name: "results-view-courses-assessments-student",
                    meta: {

                        title: 'Asessment marks entry', // tab title
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/results/courses/StudentsAssessment.vue")
                },
                {
                    path: "results/exam/assessment/:slug/course/:courseId/students",
                    name: "results-view-assessment-courses-students",
                    meta: {

                        title: 'Courses assessments student', // tab title
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/results/courses/StudentsAssessment.vue")
                },
                {
                    path: "results/:examId/students",
                    name: "results-view-all-courses-summary-students",
                    meta: {

                        title: 'Result summary', // tab title
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/results/courses/ViewAllStudent.vue")
                },
                {
                    path: "exams/create",
                    name: "exams-create",
                    meta: {

                        title: ' New exam', // tab title
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/exams/CreateAndUpdate.vue")

                },
                {
                    path: "academic-year",
                    name: "academic-year",
                    meta: {

                        title: 'Academic year', // tab title
                        icon:'far fa-calendar-alt',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/academic-year/Index.vue")

                }, {
                    path: "academic-class",
                    name: "academic-class",
                    meta: {

                        title: 'Classrooms', // tab title
                        icon:'fas fa-chalkboard-teacher',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/academic-class/Index.vue")

                },
                {
                    path: "graduation-academic-class",
                    name: "graduation-academic-class",
                    meta: {

                        title: 'Graduation', // tab title,
                        icon:'fas fa-graduation-cap',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/academic-class/gradaution/Index.vue")

                },
                {
                    path: "academic-class/:id",
                    name: "academic-class-course-assign-teacher",
                    meta: {

                        title: 'Add teachers to class', // tab title
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/academic-class/AssignClassCourseTeacher.vue")

                },
                {
                    path: ":classId/attendance",
                    name: "academic-class-attendance",
                    meta: {

                        title: 'Attendance', // tab title
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/academic-class/Attendance.vue")

                },
                {
                    path: ":classId/summary",
                    name: "academic-class-summary",
                    meta: {

                        // title: 'Class Summary', // tab title
                        tabClass: 'custom-tab', // custom class, optional
                        closable: true, // is tab closable, defaults to `true`
                        key: 'path',
                    },
                    component: () => import("@/view/pages/academic-class/ClassRoomSummary.vue")

                },
                {
                    path: ":classId/upgrade-class",
                    name: "upgrade-class",
                    meta: {

                        title: 'Upgrade class', // tab title
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/academic-class/UpgradeClassRoom.vue")

                },

                {
                    path: "attendance",
                    name: "class-attendance",
                    meta: {

                        title: 'Class attendance', // tab title
                        icon:' far fa-calendar-check',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/attendance/Attendance.vue")

                },


                {
                    path: "apply-for-enrolment/apply/:program",
                    name: "apply-enrolment-program",
                    meta: {

                        title: 'New enrolment', // tab title
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/enrolment/EnrolmentForm.vue")
                },
                {
                    path: "email-template",
                    name: "email-template",
                    meta: {

                        title: 'Email templates', // tab title
                        icon:'flaticon2-mail-1',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true,
                        keepAlive: false// is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/email-template/Index.vue")
                },
                {
                    path: "email-template/:role",
                    name: "email-templates",
                    meta: {

                        title: 'Email Templates',
                        icon:'flaticon2-mail-1',
                        tabClass: 'custom-tab',
                        key: 'path',
                        closable: true,
                        keepAlive: false
                    },
                    component: () => import("@/view/pages/email-template/Index.vue")
                },
                {
                    path: "email-template-roles",
                    name: "email-template-roles",
                    meta: {
                        title: 'Email Templates Roles',
                        icon:'flaticon2-mail-1',
                        tabClass: 'custom-tab',
                        key: 'path',
                        closable: true,
                        keepAlive: false
                    },
                    component: () => import("@/view/pages/email-template/Roles.vue")
                },
                {
                    path: "email-template/:id/update",
                    name: "email-templates-update",
                    meta: {
                        title: 'Edit Email Template',
                        icon:'flaticon2-mail-1',
                        tabClass: 'custom-tab',
                        key: 'path',
                        closable: true
                    },
                    component: () => import("@/view/pages/email-template/CreateAndUpdate.vue")
                },
                {
                    path: "notification",
                    name: "notification",
                    meta: {

                        title: 'Push notifications', // tab title
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true,
                        icon:'fa fa-bullhorn',
                        keepAlive: false// is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/push-notification/Index.vue")
                },
                {
                    path: "notification-topic",
                    name: "notification-topic",
                    meta: {

                        title: 'Notification topic', // tab title
                        icon: 'fa fa-concierge-bell',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true,
                        keepAlive: false// is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/notification-topics/Index.vue")
                },
                {
                    path: "leave-category",
                    name: "leave-category",
                    meta: {

                        title: 'Leave category', // tab title
                        icon:'fas fa-umbrella-beach',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true,
                        keepAlive: false// is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/leave/leave-category/Index.vue")
                },

                {
                    path: "leave",
                    name: "leave",
                    meta: {

                        title: 'Leaves', // tab title
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true,
                        icon:'fas fa-umbrella-beach',
                        keepAlive: false// is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/leave/leave/Index.vue")
                },
                {
                    path: "visitor-category",
                    name: "visitor-category",
                    meta: {

                        title: 'Visitor category', // tab title
                        icon:'fas  fa-users',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true,
                        keepAlive: false// is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/visitor/visitor-category/Index.vue")
                },
                {
                    path: "visitor",
                    name: "visitor",
                    meta: {

                        title: 'Visitors', // tab title
                        icon: 'fa fa-users',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true,
                        keepAlive: false// is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/visitor/visitor/Index.vue")
                },
                {
                    path: "zoom-user",
                    name: "zoom-user",
                    meta: {

                        title: 'Zoom users setting', // tab title
                        icon:'fas fa-video',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true,
                        keepAlive: false// is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/zoom-user/ZoomUser.vue")
                },
                {
                    path: "visitor-summary/:visitor_id",
                    name: "visitor-summary",
                    meta: {

                        title: 'Visitor summary', // tab title
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true,
                        keepAlive: false// is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/visitor/visitor/VisitorSummary.vue")
                },
                {
                    path: "email-template/:id/update",
                    name: "email-template-update",
                    meta: {

                        title: 'Edit email template', // tab title
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/email-template/CreateAndUpdate.vue")
                },
                {
                    path: "enrollments",
                    name: "enrollment-list",
                    meta: {

                        title: 'Enrollments', // tab title
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/enrolment/Index.vue")
                }, {
                    path: "admissions",
                    name: "pre-registration-list",
                    meta: {
                        permission: 'pre-registration.list',
                        title: 'Manage admissions', // tab title
                        icon:'fas fa-id-badge',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/pre-registration/Index.vue")
                }, {
                    path: "admissions/create",
                    name: "pre-registration-create",
                    meta: {
                        permission: 'pre-registration.create',
                        title: 'New admission', // tab title
                        icon: 'fas fa-plus-circle',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/pre-registration/old.vue")
                },
                {
                    path: "admissions/:enrollmentId/update",
                    name: "pre-registration-update",
                    meta: {
                        permission: 'pre-registration.edit',
                        title: 'Update admission', // tab title
                        icon: 'fas fa-edit',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/pre-registration/EditEnrollment.vue")
                },
                {
                    path: "admissions/:programId/create",
                    name: "program-admission-create",
                    meta: {
                        permission: 'pre-registration.create',
                        title: 'Create admission setting', // tab title
                        icon: 'fas fa-plus-circle',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/program/pre-registration-setting/CreateOrUpdate.vue")
                },
                {
                    path: "admissions/:programId/update/:admissionId",
                    name: "program-admission-update",
                    meta: {
                        permission: 'pre-registration.edit',
                        title: 'Update admission setting', // tab title
                        icon: 'fas fa-edit',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/program/pre-registration-setting/CreateOrUpdate.vue")
                },
                {
                    path: "rfid-cards",
                    name: "rfid-cards",
                    meta: {

                        title: 'RFID Card', // tab title
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/rfid-card/Index.vue")
                },
                {
                    path: "content",
                    name: "blogs",
                    meta: {

                        title: 'Blogs',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/blogs/Index.vue"),
                    children: [

                        {
                            path: "content/:type",
                            name: "blogs-list",
                            meta: {

                                title: 'Articles',
                                tabClass: 'custom-tab', // custom class, optional
                                key: 'path', // tab cache rule, optional
                                closable: true // is tab closable, defaults to `true`
                            },
                            component: () => import("@/view/pages/blogs/List.vue")
                        }, {
                            path: "content/:type/create",
                            name: "blogs-create",
                            meta: {

                                title: 'Add new',
                                tabClass: 'custom-tab', // custom class, optional
                                key: 'path', // tab cache rule, optional
                                closable: true // is tab closable, defaults to `true`
                            },
                            component: () => import("@/view/pages/blogs/CreateAndUpdate.vue")
                        }, {
                            path: "content/:type/update/:id",
                            name: "blogs-update",
                            meta: {

                                title: 'Update',
                                tabClass: 'custom-tab', // custom class, optional
                                key: 'path', // tab cache rule, optional
                                closable: true // is tab closable, defaults to `true`
                            },
                            component: () => import("@/view/pages/blogs/CreateAndUpdate.vue")
                        },
                    ]
                }, {
                    path: ":category/category",
                    name: "blog-category-list",
                    meta: {

                        title: 'Blog categories',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'fullPath', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/blogs/category/List.vue")
                }, {
                    path: "cart",
                    name: "cart",
                    meta: {

                        title: 'Cart',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/cart/Index.vue")
                }, {
                    path: "teacher",
                    name: "teacher",
                    meta: {

                        title: 'Teachers',
                        icon:'fas fa-user-tie',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/teacher/Index.vue")
                },
                {
                    path: "program",
                    name: "program",
                    meta: {

                        title: 'Programmes',
                        icon:'fa-solid fa-award',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/program/Index.vue")
                },
                {
                    path: "program-report",
                    name: "program-report",
                    meta: {

                        title: 'Programmes report',
                        icon: 'fas fa-newspaper',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/program/report/Index.vue")
                },
                {
                    path: "program/update/:id",
                    name: "program-update",
                    meta: {

                        title: 'Update program',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/program/CreateOrUpdateNew.vue")
                },
                {
                    path: "program/new",
                    name: "program-new",
                    meta: {

                        title: 'New program',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/program/CreateOrUpdateNew.vue")
                },
                {
                    path: "page",
                    name: "page",
                    meta: {

                        title: 'Pages',
                        icon:'flaticon2-copy',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/page/Index.vue")
                },
                {
                    path: "page/update/:id",
                    name: "page-update",
                    meta: {

                        title: 'Edit page',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/page/CreateAndUpdate.vue")
                },
                {
                    path: "page/new",
                    name: "page-new",
                    meta: {

                        title: 'New page',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/page/CreateAndUpdate.vue")
                },
                {
                    path: "home-page",
                    name: "home-page",
                    meta: {

                        title: 'Home page',
                        icon: 'flaticon2-copy',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/cms/home-page/Index.vue")
                },
                {
                    path: "home-page/:id/content",
                    name: "home-page-content",
                    meta: {

                        title: 'Homepage content',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/cms/home-page/content/Index.vue")

                },
                {
                    path: "institution",
                    name: "institution",
                    meta: {

                        title: 'Institutions',
                        icon:'fas fa-hotel',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/institution/Index.vue")
                },
                {
                    path: "quiz",
                    name: "quiz",
                    meta: {

                        title: 'Quiz',
                        icon: 'fas fa-question-circle',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/quiz/Index.vue")
                },
                {
                    path: "quiz/:id/result",
                    name: "quiz-result",
                    meta: {

                        title: 'Quiz result',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/quiz/result/Index.vue")
                },
                {
                    path: "quiz/:quiz_id/update",
                    name: "quiz-update",
                    meta: {

                        title: 'Update quiz',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/quiz/CreateOrUpdate.vue")
                },
                {
                    path: "question-bank-category",
                    name: "question-bank-category",
                    meta: {

                        title: 'Question bank categories',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/quiz/question-bank/category/Index.vue")
                }, {
                    path: "question-bank",
                    name: "question-bank",
                    meta: {

                        title: 'Question bank',
                        icon:'fas fa-piggy-bank',
                        keepAlive: false,
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/quiz/question-bank/Index.vue")
                },
                {
                    path: "question-bank/new",
                    name: "question-bank-new",
                    meta: {

                        title: 'New question bank',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/quiz/question-bank/CreateOrUpdate.vue")
                }, {
                    path: "question-bank/:id/update",
                    name: "question-bank-update",
                    meta: {

                        title: 'Edit question bank',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/quiz/question-bank/CreateOrUpdate.vue")
                },
                {
                    path: "contact-message",
                    name: "messages",
                    meta: {

                        title: 'Online inquiries',
                        icon: 'flaticon2-mail-1',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/contact/Index.vue")
                }, {
                    path: "success",
                    name: "payment-success",
                    meta: {

                        title: 'Payment success',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/cart/Success.vue")
                },
                {
                    path: "album",
                    name: "album-list",
                    meta: {

                        title: 'Albums',
                        icon:'flaticon2-image-file',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/album/Index.vue")
                },
                {
                    path: "video-gallery",
                    name: "video-gallery",
                    meta: {

                        title: 'Video galleries',
                        icon: 'fas fa-video',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/video-gallery/Index.vue")
                }, {
                    path: "video-category",
                    name: "video-category",
                    meta: {

                        title: 'Video categories',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/video-gallery/category/Index.vue")
                },
                {
                    path: "menu-manager",
                    name: "menu-manager",
                    meta: {

                        title: 'Menu manager',
                        icon: 'fas fa-bars',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/menu-manager/menu/Index.vue")
                },
                {
                    path: "menu-manager/:id/menu",
                    name: "menu-manager-menu",
                    meta: {

                        title: 'Edit menu',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/menu-manager/Index.vue")
                },
                {
                    path: "media",
                    name: "media",
                    meta: {

                        title: 'Media',
                        icon: 'flaticon2-google-drive-file',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/media/Index.vue")
                },
                {
                    path: "popup",
                    name: "popup",
                    meta: {

                        title: 'Popup',
                        icon: 'flaticon2-cube-1',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/popup/Index.vue"),
                },
                {
                    path: "popup/update/:id",
                    name: "popup-update",
                    meta: {

                        title: 'Edit popup',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/popup/CreateOrUpdate.vue")
                },
                {
                    path: "popup/new",
                    name: "popup-new",
                    meta: {

                        title: 'New popup',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/popup/CreateOrUpdate.vue")
                },
                {
                    path: "slider",
                    name: "slider",
                    meta: {

                        title: 'Slider',
                        icon:'flaticon-mark',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/slider/Index.vue"),
                }, {
                    path: "slider-type",
                    name: "slider-type",
                    meta: {

                        title: 'Slider categories',
                        icon:'flaticon-mark',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/slider/type/Index.vue"),
                },
                {
                    path: "testimonial",
                    name: "testimonial",
                    meta: {

                        title: 'Testimonials',
                        icon: 'fas fa-people-arrows',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/testimonial/Index.vue"),
                },
                {
                    path: "testimonial/:id",
                    name: "testimonial-update",
                    meta: {

                        title: 'Update testimonial',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/testimonial/CreateOrUpdate.vue")
                },
                {
                    path: "time-table",
                    name: "time-table",
                    meta: {

                        title: 'Timetable',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/time-table/TimeTable.vue"),
                },
                {
                    path: "time-table/:time_table_id/periods",
                    name: "time-table-period",
                    meta: {

                        title: 'Timetable period',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/time-table/TimeTablePeriod.vue"),
                },
                {
                    path: "testimonial/new",
                    name: "testimonial-new",
                    meta: {

                        title: 'New testimonial',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/testimonial/CreateOrUpdate.vue")
                },
                {
                    path: "result-certificate",
                    name: "result-certificate",
                    meta: {

                        title: 'Result certificate',
                        icon: 'fa-solid fa-certificate',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/program/result-certificate/Index.vue"),
                },
                {
                    path: "slider/:id",
                    name: "slider-update",
                    meta: {

                        title: 'Edit Slider',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/slider/CreateOrUpdate.vue")
                },
                {
                    path: "slider/new",
                    name: "slider-new",
                    meta: {

                        title: 'Add slider',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/slider/CreateOrUpdate.vue")
                },
                {
                    path: "education",
                    name: "education",
                    meta: {

                        title: 'Education levels',
                        icon:'fas fa-laptop-house',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/level/Index.vue"),
                },
                {
                    path: "program/:program/admission-process",
                    name: "admission-process",
                    meta: {

                        title: 'Admission process',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/program/admission-process/Index.vue"),
                },
                {
                    path: "program/:program/admission-process/new",
                    name: "admission-process-new",
                    meta: {

                        title: 'New admission process ',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/program/admission-process/CreateOrUpdate.vue")
                },
                {
                    path: "program/:program/admission-process/:process_id/update",
                    name: "admission-process-update",
                    meta: {

                        title: 'Edit admission process',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/program/admission-process/CreateOrUpdate.vue")
                },

                {
                    path: "department",
                    name: "department",
                    meta: {

                        title: 'Departments',
                        icon:'flaticon2-browser-2',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/department/Index.vue")
                },
                {
                    path: "printers",
                    name: "printer",
                    meta: {

                        title: 'Printers',
                        icon: 'fas fa-print',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/Printer/Index.vue")
                },

                {
                    path: "printing",
                    name: "printing",
                    meta: {

                        title: 'Printing',
                        icon: 'fas fa-print',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/printing/Index.vue")
                },

                {
                    path: "/printing/:userNotificationId/files",
                    name: "print-job-queue",
                    component: () => import("@/view/pages/printing/files/Index.vue"),
                    meta: {
                        title: 'Bulk printer queue',
                        key: 'fullPath',
                        icon: 'flaticon2-printer',
                        keepAlive: true,

                    }
                },

                {
                    path: "accounts/fees",
                    name: "fees",
                    meta: {

                        title: 'Fees management',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/fees/AllTransactionFee.vue")
                },

                {
                    path: "career",
                    name: "career",
                    meta: {

                        title: 'Careers',
                        icon:'fas fa-briefcase',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/career/Index.vue"),
                }, {
                    path: "career/new",
                    name: "career-new",
                    meta: {

                        title: 'New career',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/career/CreateOrUpdate.vue"),
                }, {
                    path: "career/:id/update",
                    name: "career-update",
                    meta: {

                        title: 'Edit career',
                        icon: 'fas fa-edit',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/career/CreateOrUpdate.vue"),
                },
                {
                    path: "career/:id/application",
                    name: "career-application",
                    meta: {

                        title: 'Career applications',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/career/application/Index.vue"),
                },


                {
                    path: "invoice",
                    name: "invoice",
                    meta: {

                        title: 'Invoices',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/billing/index.vue"),
                },
                {
                    path: "invoice/:id",
                    name: "invoice-detail",
                    meta: {

                        title: 'Invoice details',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/billing/invoice.vue"),
                },
                // {
                //     path: "careers/:id",
                //     name: "careers-update",
                //     component: () => import("@/view/pages/career/CreateOrUpdate.vue")
                // },
                // {
                //     path: "careers/new",
                //     name: "careers-new",
                //     component: () => import("@/view/pages/careers/CreateOrUpdate.vue")
                // },

                {
                    path: "album/:id/photos",
                    name: "album-photo",
                    meta: {

                        title: 'Album photos',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/album/value/Index.vue")

                },
                {
                    path: "faculty",
                    name: "faculty",
                    meta: {

                        title: 'Faculty ',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/faculty/Index.vue")

                }, {
                    path: "faculty/:id/teacher",
                    name: "faculty-teacher",
                    meta: {

                        title: 'Faculty member',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/faculty/teacher/Index.vue")

                },
                {
                    path: "download",
                    name: "download",
                    meta: {

                        title: 'Downloads ',
                        icon: 'flaticon2-download',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/download/Index.vue")

                },
                {
                    path: "faq",
                    name: "faq",
                    meta: {
                        permission: 'faq.list',
                        title: 'FAQ ',
                        icon: 'fas fa-question-circle',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/faq/Index.vue")

                },
                {
                    path: "faq-category",
                    name: "faq-category",
                    meta: {

                        title: 'FAQ category',
                        icon: 'fas fa-question-circle',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/faq/ManageCategory")

                },
                {
                    path: "members",
                    name: "members",
                    meta: {

                        title: 'Faculty members ',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/members/Index.vue")

                },
                {
                    path: "our-people",
                    name: "our-people",
                    meta: {

                        title: 'Our people ',
                        icon:'fa-user-tie',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/our-people/Index.vue")

                },
                {
                    path: "rooms",
                    name: "rooms",
                    meta: {

                        title: 'Rooms',
                        icon:'fas fa-building',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/rooms/Index.vue")

                },
                {
                    path: "online-class-rooms",
                    name: "online-class-rooms",
                    meta: {

                        title: 'Online classrooms ',
                        icon: 'fas fa-laptop-house',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/online-class-rooms/Index.vue")

                },
                {
                    path: "online-class-rooms-create",
                    name: "online-class-rooms-create",
                    meta: {

                        title: 'New online classroom',
                        icon: 'fas fa-plus-circle',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/online-class-rooms/CreateAndUpdate.vue")

                },
                {
                    path: "online-class-rooms/:room_id",
                    name: "online-class-rooms-update",
                    meta: {

                        title: 'Edit online classroom',
                        icon: 'fas fa-edit',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/online-class-rooms/CreateAndUpdate.vue")

                },
                {
                    path: "assignment",
                    name: "assignment",
                    meta: {

                        title: 'Assignments ',
                        icon:'flaticon-list-1',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/assignment/Index.vue")

                },
                {
                    path: "assignment-questions",
                    name: "assignment-questions",
                    meta: {

                        title: 'Assignment questions ',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/assignment/question/Index.vue")

                },
                {
                    path: "assignment/:assignment_id/submission",
                    name: "assignment-submission",
                    meta: {

                        title: 'Assignment submission',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/assignment/submission/Index.vue")
                },
                {
                    path: "assignment/:assignment_id/submission/:student_id",
                    name: "assignment-submission-detail",
                    meta: {

                        title: 'Assignment submission details',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/assignment/submission/SumissionDetail.vue")
                },
                {
                    path: "assignment/:assignment_id",
                    name: "assignment-edit",
                    meta: {

                        title: 'Edit assignment',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/assignment/NewAssignment.vue")

                },
                {
                    path: "assignment/:assignment_id/summary",
                    name: "assignment-summary",
                    meta: {

                        title: 'Assignment summary',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/assignment/AssignmentDetail.vue")

                },

                {
                    path: "lesson-planner",
                    name: "lesson-planner",
                    meta: {

                        title: 'Lesson planner',
                        icon:'fas flaticon-list-2',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/lesson-planner/Index.vue")

                },
                {
                    path: ":lesson_plan_id/lesson-planner/:topic_id/lesson",
                    name: "lesson-planner-topic-lesson",
                    meta: {

                        title: 'Topics',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/lesson-planner/lessons/Index.vue")

                },
                {
                    path: "lesson-planner/:lesson_id/summary",
                    name: "lesson-planner-summary",
                    meta: {

                        title: 'Lesson planner summary',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/lesson-planner/Summary.vue")

                },
                {
                    path: "transport/vehicle",
                    name: "vehicle",
                    meta: {

                        title: 'Vehicle',
                        icon:'fas fa-car',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/transport/vehicle/Index.vue")

                },
                {
                    path: "transport/routes-vehicles",
                    name: "routes-vehicles",
                    meta: {

                        title: 'Vehicle',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/transport/routes-vehicles/Index.vue")

                },
                {
                    path: "transport/routes/:routeId/vehicles",
                    name: "manage-routes-vehicles",
                    meta: {

                        title: 'Vehicle',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/transport/routes-vehicles/Index.vue")

                },
                {
                    path: "transport/vehicles-drivers",
                    name: "vehicles-drives",
                    meta: {

                        title: 'Vehicle drivers',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/transport/vehicles-driver/Index.vue")

                },
                {
                    path: "transport/vehicles/:vehicle_id/drivers",
                    name: "manage-vehicles-drives",
                    meta: {

                        title: 'Vehicle Drivers',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/transport/vehicles-driver/Index.vue")

                },
                {
                    path: "transport/route-category",
                    name: "route-category",
                    meta: {

                        title: 'Transport route category',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/transport/route-category/Index.vue")

                },
                {
                    path: "transport/route",
                    name: "route",
                    meta: {

                        title: 'Transport route',
                        icon:'fa fa-map-marked',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/transport/route/Index.vue")

                },
                {
                    path: "transport/stops",
                    name: "stops",
                    meta: {

                        title: 'Transport stops',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/transport/stops/Index.vue")

                },
                {
                    path: "transport/routes/:routeId/stops",
                    name: "routesStops",
                    meta: {

                        title: 'Transport stops',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/transport/stops/Index.vue")

                },
                {
                    path: "transport/routes/:routeId/summary",
                    name: "routeSummary",
                    meta: {

                        title: 'Route summary',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/transport/summary/Index.vue")

                },
                {
                    path: "transport/driver",
                    name: "driver",
                    meta: {

                        title: 'Driver',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/transport/driver/Index.vue")

                },
                {
                    path: "transport-allocation",
                    name: "transport-allocation",
                    meta: {

                        title: 'Transport allocation',
                        icon: 'fas fa-map-marker',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/transport/transport_allocations/Index.vue")

                },
                {
                    path: "courses",
                    name: "courses",
                    meta: {
                        title: 'Courses',
                        icon:'fas fa-book-open',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/courses/Courses.vue")

                },
                {
                    path: "course/:id/additional-assessment",
                    name: "course-assessment",
                    meta: {
                        title: 'Course additional assessment',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/courses/additional-assessment/Index.vue")

                },
                {
                    path: ":lesson_plan_id/course-summary",
                    name: "course-summary",
                    meta: {

                        title: 'Courses summary',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/courses/Summary.vue")

                },
                {
                    path: "new-courses",
                    name: "new-courses",
                    meta: {

                        title: 'New course',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/courses/Index.vue")

                },

                {
                    path: "print-jobs",
                    name: "print-jobs",
                    meta: {

                        title: 'Print jobs',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/id-cards/printJobsNotification.vue")

                },
                {
                    path: "all/notification",
                    name: "all-notification",
                    meta: {

                        title: 'Notifications',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/push-notification/Notification.vue")

                },
                {
                    path: "id-card",
                    name: "id-card",
                    meta: {

                        title: 'ID card',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/id-cards/Index.vue")

                },
                // {
                //     path: "result-certificate",
                //     name: "result-certificate",
                //     meta: {
                //
                //         title: 'Result certificate',
                //         closable: true // is tab closable, defaults to `true`
                //     },
                //     component: () => import("@/view/pages/result-certificate/Index.vue")
                // },

                {
                    path: "student-optional-courses",
                    name: "student-optional-courses",
                    meta: {

                        title: 'Student optional courses',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/student/courses/optional/AssignOptionalCourses.vue")

                },
                {
                    path: "setting",
                    name: "setting",
                    meta: {

                        title: 'Settings',
                        icon: 'flaticon-settings',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/setting/Index.vue")

                },
                {
                    path: "admission-setting",
                    name: "admission-setting",
                    meta: {
                        permission:"admission-setting.list",
                        title: 'Admission settings',
                        icon: 'flaticon-settings',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/setting/admission/Index.vue")

                },
                {
                    path: "parents-meet-setting",
                    name: "parents-meet-setting",
                    meta: {
                        permission:"parents-meet-setting.list",
                        title: 'Parents meet settings',
                        icon: 'flaticon-settings',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/setting/parents-meet/Index.vue")
                },
                {
                    path: "gate-pass-invitation-setting",
                    name: "gate-pass-invitation-setting",
                    meta: {
                        permission:"gate-pass-invitation-setting.list",
                        title: 'Gate pass invitation settings',
                        icon: 'flaticon-settings',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/setting/gate-pass/Index.vue")
                },
                {
                    path: "user-profile",
                    name: "profile",
                    meta: {

                        title: 'Profile',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/profile/Index.vue")

                }
                , {
                    path: "download/type",
                    name: "download-type",
                    meta: {

                        title: 'Download type',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/download/type/Index.vue")

                }, {
                    path: "sample-question/:program",
                    name: "sample-question",
                    meta: {

                        title: 'Sample questions',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/program/sample-question/Index.vue")

                }, {
                    path: "countries",
                    name: "country-list",
                    meta: {

                        title: 'Countries',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/address/country/Index.vue")

                }, {
                    path: "country/:id/state",
                    name: "state-list",
                    meta: {

                        title: 'States',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/address/state/Index.vue")

                }, {
                    path: "state/:id/city",
                    name: "city-list",
                    meta: {

                        title: 'Cities',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/address/city/Index.vue")

                }
                , {
                    path: "students/courses",
                    name: "students-courses",
                    meta: {

                        title: 'Students courses',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/student/courses/Courses.vue")

                },
                {
                    path: "students/exam",
                    name: "students-exam",
                    meta: {

                        title: 'Students exams',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/student/exams/Exam.vue")

                },
                {
                    path: "students",
                    name: "students",
                    meta: {

                        title: 'Students',
                        icon: 'fas fa-user-graduate', // optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/student/Index.vue")

                },
                {
                    path: "graduated-students",
                    name: "graduated-students",
                    meta: {

                        title: 'Graduated students',
                        icon: 'fas fa-user-graduate', // optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/student/graduated/Index.vue")

                },
                {
                    path: "kiosk_devices",
                    name: "kiosk_devices",
                    meta: {

                        title: 'KIOSK devices',
                        icon:'fas fa-tablet-alt',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/device/Index.vue")

                },
                {
                    path: "unauthorized",
                    name: "unauthorized",
                    meta: {

                        title: 'Unauthorized ',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/error/unauthorized.vue")
                },
                {
                    path: "students/create",
                    name: "students-create",
                    meta: {

                        title: 'New student',
                        icon: 'fas fa-plus-circle',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/student/CreateAndUpdate.vue")

                }, {
                    path: "students/:id/update",
                    name: "students-update",
                    meta: {

                        title: 'Edit Student',
                        icon: 'fas fa-edit',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/student/CreateAndUpdate.vue")

                },
                {
                    path: "teachers",
                    name: "teachers",
                    meta: {

                        title: 'Teachers',
                        keepAlive: false,
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/teachers/Index.vue")

                },
                {
                    path: "teachers/create",
                    name: "teachers-create",
                    meta: {

                        title: 'Add new teacher',
                        icon: 'fas fa-plus-circle',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/teachers/CreateAndUpdate.vue")

                }, {
                    path: "teachers/:id/update",
                    name: "teachers-update",
                    meta: {

                        title: 'Edit Teacher',
                        icon: 'fas fa-edit',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/teachers/CreateAndUpdate.vue")

                }, {
                    path: "staff",
                    name: "staff",
                    meta: {

                        title: 'Staff',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/staff/Index.vue")

                },
                {
                    path: "staff/create",
                    name: "staff-create",
                    meta: {

                        title: 'New staff',
                        icon: 'fas fa-plus-circle',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/staff/CreateAndUpdate.vue")

                }, {
                    path: "staff/:id/update",
                    name: "staff-update",
                    meta: {

                        title: 'Edit staff',
                        icon: 'fas fa-edit',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/staff/CreateAndUpdate.vue")

                },
                {
                    path: ":type/:id/summary",
                    name: "students-summary",
                    meta: {
                        title: 'Student summary',
                        key: 'path',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/student/Summary.vue")

                },
                {
                    path: ":type/:id/dashboard",
                    name: "students-dashboard",
                    meta: {
                        title: 'Student dashboard',
                        key: 'path',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/student/profile/StudentSummary.vue")
                },
                {
                    path: "user/:type",
                    name: "user",
                    meta: {

                        title: 'Staff management',
                        icon: 'fa fa-user-shield',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/user/Index.vue")

                },
                {
                    path: "user/:id/role",
                    name: "user-role",
                    meta: {
                        title: 'User role',
                        icon:'fas fa-user-shield',
                        keepAlive: 'false',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/user/role/ManageUserRole.vue")

                },
                {
                    path: "role",
                    name: "role",
                    meta: {
                        title: 'Role',
                        icon:'fas fa-user-shield',
                        keepAlive: false,
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/user/role/Index.vue")
                },

                {
                    path: "role/:id/permission",
                    name: "role-permission",
                    meta: {

                        title: 'Role permission',
                        icon:'fas fa-user-shield',
                        keepAlive: false,
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/user/permission/ManageRolePermission.vue")
                },

                {
                    path: "user-custom-permission/:id/role",
                    name: "user-custom-permission",
                    meta: {

                        title: 'User custom permission',
                        icon:'fas fa-user-shield',
                        keepAlive: false,
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/user/permission/ManageRoleCustomPermission.vue")

                },

                {
                    path: "permission",
                    name: "permission-list",
                    meta: {

                        title: ' Permission list',
                        icon:'fas fa-user-shield',
                        keepAlive: false,
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/user/permission/Index.vue")

                },

                {
                    path: "user/new",
                    name: "user-new",
                    meta: {

                        title: 'Add staff',
                        icon: 'fas fa-plus-circle',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/user/CreateAndUpdate.vue")

                },

                {
                    path: "user/:id/update",
                    name: "user-update",
                    meta: {

                        title: 'User update',
                        icon: 'fas fa-edit',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/user/CreateAndUpdate.vue")

                },

                {
                    path: "job-position",
                    name: "job-position",
                    meta: {

                        title: ' Job position',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/job-position/Index.vue")

                },

                {
                    path: "program/:program/questionnaire",
                    name: "questionnaire",
                    meta: {

                        title: ' Questionnaire',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/program/questionnaire/Index.vue")

                },

                {
                    name: "bulk-notification",
                    path: "bulk-notification",
                    meta: {

                        title: ' Bulk notification',
                        icon:'fas fa-mail-bulk',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/notification/Index")
                },

                {
                    name: "bulk-notification-create",
                    path: "bulk-notification-create",
                    meta: {

                        title: ' Bulk notification',
                        icon: 'fas fa-plus-circle',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/notification/Create")
                },

                {
                    name: "attendance-report",
                    path: "attendance-report",
                    meta: {

                        title: ' Attendance report',
                        icon: 'flaticon-settings',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/attendance/Report")
                },

                {
                    path: "pending-gate-passes",
                    name: "pending-gate-passes",
                    meta: {

                        title: 'Pending gate pass',
                        icon:'fas fa-spinner',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/gate-pass/Pending.vue")
                },

                {
                    path: "gate-passes",
                    name: "gate-passes",
                    meta: {

                        title: 'Gate pass',
                        icon:'fas fa-file-pdf',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/gate-pass/Index.vue")
                },

                {
                    path: "category",
                    name: "category",
                    meta: {

                        title: 'Category',
                        icon:'fas fa-database',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/category/Index.vue")
                },

                {
                    path: "user-group",
                    name: "user-group",
                    meta: {

                        title: 'Group',
                        icon:'fa fa-users',
                        key: 'fullPath',
                        keepAlive: false,
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/user/group/Index.vue")
                },

                {
                    path: "user-group/create",
                    name: "user-group-create",
                    meta: {

                        title: 'Create group',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/user/group/CreateAndUpdate.vue")
                },

                {
                    path: "user-group/:userGroupId/edit",
                    name: "user-group-edit",
                    meta: {

                        title: 'Edit group',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/user/group/CreateAndUpdate.vue")
                },

                {
                    path: "user-group/:userGroupId/member",
                    name: "user-group-member",
                    meta: {

                        title: 'Group member',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/user/group/member/Index.vue")
                },

                {
                    path: "scholarship",
                    name: "scholarship",
                    meta: {

                        title: 'Scholarship',
                        icon:'fa fa-graduation-cap',
                        key: 'fullPath',
                        keepAlive: false,
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/scholarship/Index.vue")
                },

                {
                    path: "academic-calendar",
                    name: "academic-calendar",
                    meta: {

                        title: 'Academic calendar',
                        icon: 'fa fa-calendar',
                        key: 'fullPath',
                        keepAlive: false,
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/academic-calendar/Index.vue")
                },

                {
                    path: "academic-event",
                    name: "academic-event",
                    meta: {

                        title: 'Academic event',
                        icon:'fa fa-calendar-xmark',
                        key: 'fullPath',
                        keepAlive: false,
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/academic-event/Index.vue")
                },

                {
                    path: "academic-calendar/:academicCalendarId/events",
                    name: "assign-calendar-event",
                    meta: {

                        title: 'Assign event',
                        key: 'fullPath',
                        keepAlive: false,
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/academic-calendar/AssignEvent.vue")
                },

                {
                    path: "academic-calendar/:academicCalendarId",
                    name: "view-academic-calendar",
                    meta: {

                        title: 'Academic calendar',
                        key: 'fullPath',
                        keepAlive: false,
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/academic-calendar/ViewCalendar.vue")
                },

                {
                    path: "inventory-item",
                    name: "inventory-item",
                    meta: {

                        title: 'Inventory item',
                        icon: 'fas fa-box',
                        key: 'fullPath',
                        keepAlive: false,
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/inventory-item/Index.vue")
                },

                {
                    path: "inventory-item/create",
                    name: "create-inventory-item",
                    meta: {

                        title: 'New inventory item',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/inventory-item/CreateAndUpdate.vue")
                },

                {
                    path: "inventory-item/:inventoryItemId/edit",
                    name: "edit-inventory-item",
                    meta: {

                        title: 'Edit inventory item',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/inventory-item/CreateAndUpdate.vue")
                },

                {
                    path: "inventory-item-stock",
                    name: "inventory-item-stock",
                    meta: {

                        title: 'Inventory item stock',
                        icon: 'fas fa-truck-moving',
                        key: 'fullPath',
                        keepAlive: false,
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/inventory-item-stock/Index.vue")
                },

                {
                    path: "inventory-item-stock/create",
                    name: "create-inventory-item-stock",
                    meta: {

                        title: 'New inventory item stock',
                        key: 'fullPath',
                        icon: 'fas fa-plus-circle',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/inventory-item-stock/CreateAndUpdate.vue")
                },

                {
                    path: "inventory-item-stock/:inventoryItemStockId/edit",
                    name: "edit-inventory-item-stock",
                    meta: {

                        title: 'Edit inventory item stock',
                        icon: 'fas fa-edit',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/inventory-item-stock/CreateAndUpdate.vue")
                },

                {
                    path: "inventory-assigned-list",
                    name: "inventory-assigned-list",
                    meta: {

                        title: 'Inventory assign list',
                        icon: 'fas fa-boxes',
                        key: 'fullPath',
                        keepAlive: false,
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/inventory-assigned-list/Index.vue")
                },

                {
                    path: "inventory-assigned-list/create",
                    name: "create-inventory-assigned-list",
                    meta: {

                        title: 'New inventory assign list',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/inventory-assigned-list/CreateAndUpdate.vue")
                },

                {
                    path: "inventory-assigned-list/:inventoryAssignedListId/edit",
                    name: "edit-inventory-assigned-list",
                    meta: {

                        title: 'Edit inventory assign list',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/inventory-assigned-list/CreateAndUpdate.vue")
                },

                {
                    path: "hostel-type",
                    name: "hostel-type",
                    meta: {

                        title: 'Hostel type',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/hostel/hostel-type/Index.vue")
                },

                {
                    path: "hostel",
                    name: "hostel",
                    meta: {

                        title: 'Hostel',
                        icon: 'fas fa-bed',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/hostel/Index.vue")
                },

                {
                    path: "hostel-building",
                    name: "hostel-building",
                    meta: {

                        title: 'Hostel building',
                        icon:'fas fa-building',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/hostel/hostel-building/Index.vue")
                },

                {
                    path: "hostel-room",
                    name: "hostel-room",
                    meta: {

                        title: 'Hostel room',
                        icon:'fas fa-building',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/hostel/hostel-room/Index.vue")
                },

                {
                    path: "hostel-resident",
                    name: "hostel-resident",
                    meta: {

                        title: 'Hostel resident',
                        icon:'fas fa-users',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/hostel/hostel-resident/Index.vue")
                },

                {
                    path: "general-attendance",
                    name: "general-attendance",
                    meta: {

                        title: 'Check-in/out',
                        icon:'fas fa-school',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/general-attendance/Index.vue")
                },

                {
                    path: "student-parents-visit-logs",
                    name: "student-parents-visit-logs",
                    meta: {

                        title: 'Parents meet',
                        icon:'fa-regular fa-calendar-days',
                        key: 'fullPath',
                        closable: true,
                        keepAlive: false, // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/student-parents-visit-logs/Index.vue")
                },

                {
                    path: "student-parents-visit-logs/create",
                    name: "create-student-parents-visit-logs",
                    meta: {

                        title: 'New meet request',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/student-parents-visit-logs/CreateAndUpdate.vue")
                },

                {
                    path: "student-parents-visit-logs/:studentParentsVisitLogId/edit",
                    name: "edit-student-parents-visit-logs",
                    meta: {

                        title: 'Edit meet request',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/student-parents-visit-logs/CreateAndUpdate.vue")
                },

                {
                    path: "character-certificate",
                    name: "character-certificate",
                    meta: {

                        title: 'Character certificate',
                        icon:'fa-solid fa-id-card-clip',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/setting/certificate/CharacterCertificate.vue")
                },

                {
                    path: "id-card-template",
                    name: "id-card-template",
                    meta: {

                        title: 'ID card template',
                        icon:'fa-regular fa-id-card',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/setting/templates/IdCardTemplate.vue")
                },

                {
                    path: "alumnus",
                    name: "alumnus",
                    meta: {

                        title: 'Alumnus',
                        icon:'fas fa-user-friends',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/alumnus/Index.vue")
                },

                {
                    path: "pending-alumnus",
                    name: "pending-alumnus",
                    meta: {

                        title: 'Pending alumnus',
                        icon:'fas fa-user-friends',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/alumnus/PendingAlumnus.vue")
                },

                {
                    path: "/alumnus/create",
                    name: "alumnus-create",
                    component: () => import("@/view/pages/alumnus/CreateOrUpdate.vue"),
                    meta: {
                        title: 'Add alumnus',
                        icon:'fas fa-plus',
                        key: 'fullPath',
                    }
                },

                {
                    path: "/alumnus/:alumnusId/edit",
                    name: "alumnus-edit",
                    component: () => import("@/view/pages/alumnus/CreateOrUpdate.vue"),
                    meta: {
                        title: 'Add alumnus',
                        icon:'fas fa-plus',
                        key: 'fullPath',
                    }
                },

                {
                    path: "alumnus-join-request",
                    name: "academic-graduated-program",
                    meta: {

                        title: 'Pending alumni approval',
                        icon:'fas fa-spinner',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/alumnus/AlumnusGraduatedProgram/Index.vue"),
                },

                {
                    path: "finance",
                    name: "finance-base",
                    meta: {

                        title: ' Account & finance',
                        icon:'fas fa-money-check',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/finance/Base.vue"),
                    children: [
                        {
                            path: "dashboard",
                            name: "finance",
                            meta: {

                                title: ' Finance summary',

                                closable: true, // is tab closable, defaults to `true`
                                tabClass: 'custom-tab', // custom class, optional
                                key: 'fullPath', // tab cache rule, optional
                            },
                            component: () => import("@/view/pages/finance/Dashboard.vue")
                        },
                        {
                            path: "transaction-head",
                            name: "finance-transaction-head",
                            meta: {

                                title: ' Transaction headings',
                                icon:'fas fa-file-invoice-dollar',
                                tabClass: 'custom-tab', // custom class, optional
                                key: 'fullPath', // tab cache rule, optional
                                closable: true // is tab closable, defaults to `true`
                            },
                            component: () => import("@/view/pages/finance/transaction-heads/Index.vue")
                        },
                        {
                            path: "payment-type/:transactionHeadId",
                            name: "finance-payment-type",
                            meta: {

                                title: ' Finance summary',
                                key: 'fullPath',
                                icon:'fas fa-file-invoice-dollar',
                                closable: true // is tab closable, defaults to `true`
                            },
                            component: () => import("@/view/pages/finance/payment-type/Index.vue")
                        },
                        {
                            path: "transaction/records",
                            name: "finance-history",
                            meta: {

                                title: 'Transactions history',
                                key: 'fullPath',
                                closable: true // is tab closable, defaults to `true`
                            },
                            component: () => import("@/view/pages/finance/history/Index.vue")
                        },
                        {
                            path: "transaction/:status/records",
                            name: "finance-history-status",
                            meta: {

                                title: 'Transactions history',
                                key: 'fullPath',
                                closable: true // is tab closable, defaults to `true`
                            },
                            component: () => import("@/view/pages/finance/history/Index.vue")
                        },
                        {
                            path: "create/:type",
                            name: "finance-history-type",
                            meta: {

                                title: ' Add transaction',
                                key: 'fullPath',
                                closable: true // is tab closable, defaults to `true`
                            },
                            component: () => import("@/view/pages/finance/history/NewTransaction.vue")
                        },
                        {
                            path: ":type/update/:id",
                            name: "finance-history-type-update",
                            meta: {

                                title: 'Update transaction',
                                key: 'fullPath',
                                closable: true // is tab closable, defaults to `true`
                            },
                            component: () => import("@/view/pages/finance/history/UpdateTransaction.vue")
                        }, {
                            path: "transaction/view/:id/invoice",
                            name: "finance-history-invoice",
                            meta: {

                                title: 'Invoice',
                                key: 'fullPath',
                                closable: true // is tab closable, defaults to `true`
                            },
                            component: () => import("@/view/pages/finance/history/invoice/Index.vue")
                        },
                    ]
                },

                {
                    name: "entrance-exam",
                    path: "entrance-exam",
                    meta: {
                        permission: 'entrance-exam.list',
                        title: 'Entrance exam',
                        icon:'fa fa-calendar-xmark',
                        key: 'fullPath',
                        keepAlive: false,
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages//pre-registration/entrance-exam/Index")
                },

                {
                    name: "entrance-exam-new",
                    path: "entrance-exam-new",
                    meta: {
                        permission: 'entrance-exam.create',
                        title: 'Add entrance exam',
                        icon:'fas fa-plus',
                        key: 'fullPath',
                        keepAlive: false,
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages//pre-registration/entrance-exam/CreateOrUpdate")
                },

                {
                    name: "entrance-exam-update",
                    path: ":id/update",
                    meta: {
                        permission: 'entrance-exam.edit',
                        title: 'Update entrance exam',
                        icon:'fas fa-edit',
                        key: 'fullPath',
                        keepAlive: false,
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages//pre-registration/entrance-exam/CreateOrUpdate")
                },

                {
                    name: "entrance-exam-manage-candidate",
                    path: ":id/entrance-exam-manage-candidate",
                    meta: {
                        permission: 'entrance-exam.view-candidates',
                        title: 'Manage exam candidates',
                        icon:'fas fa-edit',
                        key: 'fullPath',
                        keepAlive: false,
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages//pre-registration/entrance-exam/ManageCandidate")
                },

                {
                    name: "entrance-exam-set-result",
                    path: ":id/set-result",
                    meta: {
                        permission: 'entrance-exam.set-result',
                        title: 'Entrance exam result',
                        icon:'fas fa-file-edit',
                        key: 'fullPath',
                        keepAlive: false,
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages//pre-registration/entrance-exam/SetResult")
                },

                {
                    path: "/level",
                    name: "level",
                    meta: {
                        permission: 'level',
                        title: 'Level',
                        key: 'fullPath',
                        keepAlive: false,
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/level/Index.vue")
                },

                {
                    path: "/general-request-category",
                    name: "general-request-category",
                    meta: {
                        permission: 'general-request-category',
                        title: 'General request category',
                        key: 'fullPath',
                        keepAlive: false,
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/general-request/category/Index.vue")
                },

                {
                    path: "/general-request/:type",
                    name: "general-request",
                    meta: {
                        permission: 'general-request',
                        title: 'General request',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/general-request/Index.vue")
                },

                {
                    // the 404 route, when none of the above matches
                    path: "/404",
                    name: "404",
                    meta: {
                        permission: '404',
                        title: '404',
                        key: 'fullPath',
                        keepAlive: false,
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/error/Error-1.vue")
                },
            ],
        },
        {
            path: "/error",
            name: "error",
            component: () => import("@/view/pages/error/Error.vue"),
            children: [
                {
                    path: "/error-1",
                    name: "error-1",
                    component: () => import("@/view/pages/error/Error-1.vue")
                },
                {
                    path: "/error-2",
                    name: "error-2",
                    component: () => import("@/view/pages/error/Error-2.vue")
                },
                {
                    path: "/error-3",
                    name: "error-3",
                    component: () => import("@/view/pages/error/Error-3.vue")
                },
                {
                    path: "/error-4",
                    name: "error-4",
                    component: () => import("@/view/pages/error/Error-4.vue")
                },
                {
                    path: "/error-5",
                    name: "error-5",
                    component: () => import("@/view/pages/error/Error-5.vue")
                },
                {
                    path: "/error-6",
                    name: "error-6",
                    component: () => import("@/view/pages/error/Error-6.vue")
                },

            ]
        },
        {
            path: "",
            component: () => import("@/view/pages/auth/Auth"),
            children: [
                {
                    name: "login",
                    path: "login",
                    component: () => import("@/view/pages/auth/Login")
                },
                // {
                //     name: "register",
                //     path: "register",
                //     component: () => import("@/view/pages/auth/Register")
                // },
                {
                    name: "verification",
                    path: "verification",
                    component: () => import("@/view/pages/auth/Verification")
                },
                {
                    name: "forgot-password",
                    path: "forgot-password",
                    component: () => import("@/view/pages/auth/ForgotPassword")
                },
                {
                    name: "reset-password",
                    path: "reset-password",
                    component: () => import("@/view/pages/auth/ResetPassword")
                },
            ]
        },
        {
            path: "*",
            redirect: "/404"
        },

    ]
});
