import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import {VERIFY_AUTH} from "@/core/services/store/auth.module";
import {RESET_LAYOUT_CONFIG} from "@/core/services/store/config.module";
import CKEditor from "ckeditor4-vue";
import VueHtml2pdf from 'vue-html2pdf'
import GeneralMixin from "@/mixins/GeneralMixin";
// import RouterMixin from "@/mixins/RouterMixin";
import VNepaliDatePicker from 'v-nepalidatepicker';
import 'material-design-icons-iconfont/dist/material-design-icons.css'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faUserSecret)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)


import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

Vue.use(CKEditor);
Vue.config.productionTip = false;

Vue.use(require('vue-moment'));

import VueClipboard from 'vue-clipboard2'
import Verte from 'verte';
import 'verte/dist/verte.css';

// import RouterTab and styles
import RouterTab from 'vue-router-tab'
import 'vue-router-tab/dist/lib/vue-router-tab.css'



Vue.component(Verte.name, Verte);
Vue.use(Verte);
Vue.use(VueClipboard)
import DatetimePicker from 'vuetify-datetime-picker'
// (Optional) import 'vuetify-datetime-picker/src/stylus/main.styl'

Vue.use(DatetimePicker)
Vue.use(VueHtml2pdf)
// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";

window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";

window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/inline-svg";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";
import Snotify from 'vue-snotify';
import Vuelidate from "vuelidate/src";
import VueConfirmDialog from 'vue-confirm-dialog'
import VueBus from 'vue-bus';
import {SET_CAPTCHA} from "./core/services/store/config.module";

Vue.use(VueBus);

// API service init
ApiService.init();
Vue.use(Snotify);
Vue.use(VueConfirmDialog);
Vue.use(Vuelidate);
Vue.use(localStorage);
Vue.use(GeneralMixin);
// Vue.mixin(RouterMixin);

// Remove this to disable mock API
//MockService.init();
Vue.use(VNepaliDatePicker);
//filters
Vue.filter('capitalize', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
})

/** Vue Filters Start */
Vue.filter('truncate', function (text, length, suffix) {
    if (text.length > length) {
        return text.substring(0, length) + suffix;
    } else {
        return text;
    }
});

Vue.filter('format_status', function (value) {
    if (!value.includes('_')) return value
    value = value.split('_').join(' ')
    return value;
})
Vue.filter('format_dash', function (value) {
    if (!value.includes('-')) return value
    value = value.split('-').join(' ')
    return value;
})

if (!window.localStorage.getItem('XSRF-TOKEN')) {
    Vue.axios.get("/sanctum/csrf-cookie").then(() => {
        Vue.axios.get("/site-setting/colors").then((response) => {
            store.dispatch(SET_CAPTCHA, response.data);
        });
    });
}

// let routerMixin = RouterMixin;
router.beforeEach((to, from, next) => {
    // store.commit('SET_MENU_SUBMENU', to.meta.permission || '');

    // Ensure we checked auth before each page load.
    if (to.name != 'login' && to.name != 'logout' && to.name != 'register' && to.name != 'reset-password' && to.name != 'verification' && to.name != 'forgot-password') {
        // if(routerMixin.checkIsAccessibleForRouter(store.state.RouterPermission.menu, store.state.RouterPermission.subMenu)) {
            Promise.all([store.dispatch(VERIFY_AUTH, to)]).then(next);
        // } else {
        //     next('/404');
        // }
        if (!store.state.UserPermission.permissions.length)
            store.dispatch('GET_USER_PERMISSIONS');
    } else {
        Promise.all([to]).then(next);
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);
    }

    // reset config to initial state
    store.dispatch(RESET_LAYOUT_CONFIG);


    // Scroll page to top on every route change
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);
});


Vue.use(RouterTab)

new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: h => h(App)
}).$mount("#app");
