import Apiservice from '@/core/services/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;


export default class RoleCustomPermission {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user/role`;
    }

    getAllSubmenuPermissions() {
        let url = `${API_URL}user/role/custom-permission/submenu/permission-list`;
        return apiService.get(url);
    }

    savePermission(data) {
        let url = `${API_URL}user/role/custom-permission/submenu/save-permission`;
        return apiService.post(url, data);
    }

    userPermissionList() {
        let url = `${API_URL}user/role/custom-permission/submenu/user-permission-list`;
        return apiService.get(url);
    }

    assignedPermissionToRole(id) {
        let url = `${API_URL}user/role/custom-permission/submenu/role/${id}/assigned-permission`;
        return apiService.get(url);
    }

    rebootPermission() {
        let url = `${API_URL}user/role/custom-permission/submenu/reboot-permission`;
        return apiService.get(url);
    }
}
