import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import SanctumService from "@/core/services/sanctum.service";
import { API_URL } from "@/common/config";

const ApiService = {
  init() {
      Vue.use(VueAxios, axios);
      Vue.axios.defaults.baseURL = API_URL;
      Vue.axios.defaults.withCredentials = true;
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
     /* Vue.axios.defaults.headers.common[
          "Authorization"
      ] = `Bearer ${SanctumService.getToken()}`;*/
  },

  query(resource, params) {
      return Vue.axios.get(resource, params).catch(error => {
          //
          throw new Error(`[KT] ApiService ${error}`);
      });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
      slug = (slug) ? `${slug}` : ''
      return Vue.axios.get(`${resource}${slug}`).catch(error => {
          if (error.response.status == 401 || error.response.status == 403  ) {
              this.unauthorized();
          }
          throw error.response.data;
          throw new Error(`[KT] ApiService ${error}`);
      });
  },

    getFile(resource, slug = "") {
        slug = (slug) ? `${slug}` : ''
        return Vue.axios.get(`${resource}${slug}`,{
            responseType: 'blob',
            timeout: 30000,
        }).catch(error => {
            if (error.response.status == 401 || error.response.status == 403  ) {
               this.unauthorized();
            }
            throw new Error(`[KT] ApiService ${error}`);
        });
    },

    unauthorized(){
       // window.location.href = '/#/unauthorized';
    },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
      return Vue.axios.post(`${resource}`, params).catch(error => {
          if (error.response.status == 401 || error.response.status == 403  ) {
              this.unauthorized();
          }
          throw error.response.data;
          throw new Error(`[KT] ApiService ${error}`);
      });
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
      return Vue.axios.put(`${resource}/${slug}`, params).catch(error => {
          if (error.response.status == 401 || error.response.status == 403  ) {
              this.unauthorized();
          }
          throw new Error(`[KT] ApiService ${error}`);
      });
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
      return Vue.axios.put(`${resource}`, params).catch(error => {
          if (error.response.status == 401 || error.response.status == 403  ) {
              this.unauthorized();
          }
          throw error.response.data;
          throw new Error(`[KT] ApiService ${error}`);
      });
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
      return Vue.axios.delete(resource).catch(error => {
          //
          if (error.response.status == 401 || error.response.status == 403  ) {
              this.unauthorized();
          }
          throw new Error(`[RWV] ApiService ${error}`);
      });
  }
};

export default ApiService;
