
const RouterPermission = {

    state: {
        menu: '',
        subMenu: '',
    },
    mutations: {
        SET_MENU_SUBMENU: (state, payload) => {
            if(payload) {
                payload = payload.split('.');
                state.menu = payload[0];
                state.subMenu = payload[1];
            } else {
                state.menu = '';
                state.subMenu = '';
            }
        },
    }
    ,
}
export default RouterPermission;